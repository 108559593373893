const positions = [
  {
    id: "devopsEngineer",
    title: "DevOps Engineer",
    description: "Full-Time - Rome, Italy or Remote",
    details: {
      description: [
        "At Exein we are looking for a DevOps Engineer that is passionate about designing, implementing and scaling large event-driven and microservice based platforms. Our mission is to empower developers with the tools they need to build a more secure IoT landscape.",
        "As a DevOps engineer you will be focusing your time on research, designing and scaling our cloud operations to be able to supports millions of IoT devices; working closely with the Engineering team, you will help enable Engineers to iterate rapidly at scale. If you are a great DevOps engineer with strong understanding of cloud infrastructure and workloads, Infrastructure as Code and CI/CD implementations, we definitely want to hear from you!",
      ],
      responsibilities: [
        "Design and implement cloud platform requirements to support event-driven and microservice based architectures;",
        "Building of robust continuous integration and delivery pipelines with quality gates and metrics;",
        "Automate the setup and teardown of ephemeral environments for engineers;",
        "Optimize cloud security and operations through best practices.",
      ],
      requirements: [
        "Knowledge and experience with IaC tools such as Terraform;",
        "Knowledge and experience with container orchestration (i.e. native K8s, EKS, OpenShift);",
        "Experience with automation tools (e.g. Ansible) with strong focus on reproducible infrastructure;",
        "Experience with AWS along with its services and implementation patterns.",
      ],
      preferred: [
        "Experience on operational reliability and observability – from tracing, to logging to healthchecks and smart deployment strategies;",
        "Ability to define standards in the creation of services, tooling and guidelines for Engineers.",
      ],
    },
  },
  {
    id: "backendEngineer",
    title: "Backend Engineer",
    description: "Full-Time - Rome, Italy or Remote",
    details: {
      description: [
        "At Exein we are looking for a Backend Engineer that is keen on designing, building and scaling software platforms and products. Our mission is to empower developers with the tools they need to build a more secure IoT landscape.",
        "As a Backend Engineer you will be responsible for designing, prototyping, developing and maintaining Exein's platforms. This includes data streaming pipelines, REST APIs as well as internal services that enable Exein's mission. You will be working closely with the engineering team to shape the user experience that our developer tools and products provide to our customers. If you are a great Backend Engineer with design and implementation experience and skills, we definitely want to hear from you!",
      ],
      responsibilities: [
        "Design, develop, update and maintain public API and backend services;",
        "Prototype and deliver new features and applications;",
        "Contribute to the architecture and design (architecture, design patterns, reliability and scaling) of new and current systems.",
      ],
      requirements: [
        "Experience with Git and Docker;",
        "Experience with Postgres and Redis;",
        "Experience with Kafka or Redpanda;",
        "Functional programming experience;",
        "Experience with micro-services architecture;",
        "Experience with Restful web services building ergonomic APIs;",
        "Experience in writing modern high-performance and asynchronous applications;",
        "Experience with AWS.",
      ],
      preferred: [
        "Knowledge in Rust is highly valuable;",
        "Knowledge in TimescaleDB and Postgres storage engine is highly valuable;",
        "Knowledge and experience with GraphQL is a plus;",
        "Experience with firmware, kernel or binary analysis is a plus.",
      ],
    },
  },
  {
    id: "juniorFrontend",
    title: "Junior Frontend Developer",
    description: "Full-Time - Rome, Italy or Remote",
    details: {
      description: [
        "At Exein we're looking for a Junior Front End Developer passionate about programming, design and technology. Our mission is to empower developers with the tools they need to build a more secure IoT landscape.",
        "As a Junior Front End Developer, you will be responsible for prototyping, developing and maintaining Exein's user-facing web applications. You will be working closely with the whole tech team to shape the user experience that our developer tools provide to our customers. If you are a great Junior Front End Developer and are passionate about next generation cybersecurity developer tools, we definitely want to hear from you.",
      ],
      responsibilities: [
        "Develop, update and maintain web application functionalities;",
        "Prototype and deliver mockups for new features and applications on a tight schedule;",
        "Participate in planning and work estimation with the UX team and the whole tech team;",
        "Review code quality.",
      ],
      requirements: [
        "Knowledge and experience with HTML, CSS, JavaScript, Typescript;",
        "Knowledge and experience with React;",
        "Knowledge and experience with some state management solutions like Context API, Redux, React query;",
        "Experience with RESTful web services;",
        "Experience with Git;",
        "Excellent communication skills;",
        "Growth mindset, willingness to learn, problem-solving skills, and being a real team player.",
      ],
      preferred: [
        "Familiarity with code optimization and clean code techniques;",
        "Knowledge and experience with Next.js.",
      ],
    },
  },
  {
    id: "systemsEngineer",
    title: "Systems Security Engineer",
    description: "Full-Time - Rome, Italy or Remote",
    details: {
      description: [
        "At Exein we're looking for a System Engineer passionate about low level programming and state of the art monitoring technologies. Our mission is to empower developers with the tools they need to build a more secure IoT landscape.",
        "As a System Engineer, you will be responsible for designing, prototyping, developing and maintaining the integration between Exein's core technologies and new platforms and stacks we need to integrate with. Moreover, you will work in close contact with some of our biggest customers in order document and plan for new features they require. If you are a great System Engineer with strong low level skills and are passionate about next generation cyber-security, we definitely want to hear from you.",
      ],
      responsibilities: [
        "Implement low-level runtime security agents for Linux and RTOS systems",
        "Contribute to open source projects, both maintained by Exein and by third parties",
        "Contribute to the architecture and design (architecture, design patterns, reliability and scaling) of new and current systems",
        "Review code quality",
      ],
      requirements: [
        "Knowledge and experience with Rust",
        "Knowledge and experience with the Linux kernel",
        "Knowledge and experience with security related topics and research",
        "Experience with Git",
      ],
      preferred: [
        "Knowledge and experience with eBPF",
        "Knowledge and experience with Yocto and Docker",
        "Knowledge and experience with real-time operating systems",
        "Experience with other tracing technologies",
        "Experience with C/C++, Python languages",
        "Experience with applied machine learning and anomaly detection",
        "Experience with IDS",
      ],
    },
  },
];

export default positions;
